import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Layout, Menu, Typography, Table, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { InboxOutlined } from '@ant-design/icons';
import { jsPDF } from 'jspdf';
import { CSVLink } from 'react-csv';
import './Dashboard.css'; // Make sure this file has the necessary styles
import logo from './logo-f.png';
import { VuesaxBoldHome21 } from "./icons/VuesaxBoldHome21";

// Destructure components from Ant Design
const { Title, Paragraph } = Typography;
const { Dragger } = Upload;
const { Sider, Content } = Layout;

const PdfAuthenticator = () => {
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [results, setResults] = useState([]);
    const [loadingResults, setLoadingResults] = useState(false);

    useEffect(() => {}, []); // Empty dependency array ensures this runs once on component mount

    // Check if user has client role
    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role !== 'client') {
            navigate('/dashboard'); // Redirect if not client
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        navigate('/'); // Redirect to login after logout
    };

    const props = {
        multiple: true, // Allow multiple file selection
        onRemove: (file) => {
            setFileList(prevFileList => {
                const index = prevFileList.indexOf(file);
                const newFileList = prevFileList.slice();
                newFileList.splice(index, 1);
                return newFileList;
            });
        },
        beforeUpload: (file) => {
            if (file.type !== 'application/pdf') {
                message.error(`${file.name} is not a pdf file`);
                return Upload.LIST_IGNORE;
            }
            setFileList(prevFileList => [...prevFileList, file]);
            return false;
        },
        fileList,
    };

    // Convert PDF file to Base64
    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]); // Extract base64 part of the result
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    // Handle file upload and API request
    const handleUpload = async () => {
        setUploading(true);
        setLoadingResults(true);

        try {
            const proxyUrl = 'http://localhost:3006/'; // Proxy URL to bypass CORS
            const apiUrl = 'https://ping.arya.ai/api/v1/document-tampering-detection';
            const token = 'c974fc99a2373cc0a228e3e34fd4f819'; // Your API token

            // Convert each file to Base64 and send API request
            const results = await Promise.all(fileList.map(async (file) => {
                const base64 = await fileToBase64(file);
                const response = await axios.post(proxyUrl + apiUrl, {
                    doc_type: 'image', // Adjust if necessary
                    doc_base64: base64,
                    req_id: Date.now().toString() // Unique request ID
                }, {
                    headers: {
                        'token': token,
                        'content-type': 'application/json'
                    }
                });

                return {
                    ...response.data,
                    fileName: file.name // Include the file name in the result
                };
            }));

            setResults(results);
            message.success('Upload and validation completed successfully.');
        } catch (error) {
            console.error(error);
            message.error('Upload or validation failed.');
        } finally {
            setUploading(false);
            setLoadingResults(false);
        }
    };

    // Generate PDF
    const generatePDF = () => {
        const doc = new jsPDF();
        doc.text('Results', 10, 10);

        results.forEach((result, index) => {
            doc.text(`File ${result.fileName}: ${result.isTampered ? 'Tampered' : 'Not Tampered'}`, 10, 20 + index * 10);
        });

        doc.save('results.pdf');
    };

    // Table row style based on tampering status
    const getRowClassName = (record) => {
        return record.tampered === 'Yes' ? 'row-tampered' : 'row-not-tampered';
    };

    const columns = [
        { title: 'File Name', dataIndex: 'fileName', key: 'fileName' },
        { title: 'Tampered', dataIndex: 'tampered', key: 'tampered' },
        { title: 'Severity', dataIndex: 'details', key: 'details' },
        { title: 'Download', dataIndex: 'download', key: 'download', render: (text, record) => (
            <Button onClick={() => window.open(record.fileUrl)} type="link">Download</Button>
        ) },
    ];

    const data = results.map((result, index) => ({
        key: index,
        fileName: result.fileName,
        tampered: result.isTampered ? 'Yes' : 'No',
        details: result.severity,
        fileUrl: fileList[index] ? URL.createObjectURL(fileList[index]) : '', // Create download URL for each file
    }));

    return (
        <Layout className="layout">
            <Sider width={200} className="frame-23">
                <div className="frame-24">
                    <div className="logo-container">
                        <img src={logo} alt="Capital OS Logo" className="logo-image-1" />
                    </div>
                    <div className="frame-25">
                        <Menu theme="light" mode="vertical" defaultSelectedKeys={['2']}>
                            <div className="frame-26">
                                <Menu.Item key="2">
                                    <VuesaxBoldHome21 className="icon-instance-node" />
                                    <Link to="/client/dashboard" className="text-wrapper-20">Dashboard</Link>
                                </Menu.Item>
                            </div>
                            <div className="frame-27">
                                {localStorage.getItem('token') && (
                                    <Menu.Item key="4">
                                        <Button type="link" onClick={handleLogout} className="text-wrapper-20">Logout</Button>
                                    </Menu.Item>
                                )}
                            </div>
                        </Menu>
                    </div>
                </div>
            </Sider>
            <Layout>
                <Content style={{ padding: '24px' }}>
                    <div className="site-layout-content">
                        <div className='glassmorphism'>
                            <Title level={2} className='pdf-title'>PDF Authenticator</Title>
                            <Paragraph className='pdf-desc'>
                                This expense bill authenticator is a tool that can help you identify if an expense bill PDF has been tampered with.
                                It uses a machine learning model trained on a dataset of original and modified bills to detect inconsistencies and
                                anomalies in the text of the bill. The tool can also pinpoint the exact location and nature of any tampering that is detected.
                            </Paragraph>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag files to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload of PDF files.</p>
                            </Dragger>
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={fileList.length === 0}
                                loading={uploading}
                                style={{ marginTop: 16 }}
                            >
                                {uploading ? 'Uploading' : 'Start Validating'}
                            </Button>
                            <Button
                                type="primary"
                                onClick={generatePDF}
                                disabled={results.length === 0}
                                style={{ marginTop: 16, marginLeft: 8 }}
                            >
                                Download PDF
                            </Button>
                            <CSVLink
                                data={data}
                                headers={columns.map(col => ({ label: col.title, key: col.dataIndex }))}
                                filename={"results.csv"}
                            >
                                <Button
                                    type="primary"
                                    disabled={results.length === 0}
                                    style={{ marginTop: 16, marginLeft: 8 }}
                                >
                                    Download CSV
                                </Button>
                            </CSVLink>
                        </div>
                        <div className='glassmorphism'>
                            <Title level={2} className='pdf-title'>Results</Title>
                            {loadingResults ? <Spin /> : (
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    rowClassName={getRowClassName}
                                    pagination={false}
                                />
                            )}
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default PdfAuthenticator;
