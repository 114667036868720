// frontend/src/RichTextEditor.jsx
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const RichTextEditor = ({ value, onChange }) => {
  return (
    <ReactQuill 
      value={value} 
      onChange={onChange} 
      modules={modules}  // Configuration for the editor's toolbar
      formats={formats}  // Allowed text formats
      theme="snow"      // Theme for the editor's appearance
    />
  );
};

// Quill modules and formats configuration
const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export default RichTextEditor;
