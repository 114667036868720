// // client/src/pages/ClientDashboard.jsx (or similar naming)
// import React, { useState, useEffect } from 'react';
// import { Tabs, Card, Row, Col } from 'antd';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { Button, Layout, Menu } from 'antd';
// import { Content, Header } from 'antd/es/layout/layout';
// import './Dashboard.css'; // Make sure this file has the necessary styles
// import logo from './logo-f.png';

// const { TabPane } = Tabs;

// const ClientDashboard = () => {
//   const navigate = useNavigate();

//   useEffect(() => {

//   }, []); // Empty dependency array ensures this runs once on component mount

//   // Check if user has client role
//   useEffect(() => {
//     const role = localStorage.getItem('role');
//     if (role !== 'client') {
//       navigate('/dashboard'); // Redirect if not client
//     }
//   }, [navigate]);

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('role');
//     navigate('/'); // Redirect to login after logout
//   };

//   const cardData = [
//     {
//       title: 'PDF Authenticator',
//       description: 'This expense bill authenticator is a tool that can help you identify if an expense bill PDF has been tampered with. It uses a machine learning model trained on a dataset of original and modified bills to detect inconsistencies and anomalies in the text of the bill. The tool can also pinpoint the exact location and nature of any tampering that is detected.',
//       status: 'Online',
//       link: '/pdf-authenticator', // Replace with actual link
//     }
//   ];

//   return (
//      <Layout className="layout">
//       <Header className="header">
//         <div className="logo-container">
//           <img src={logo} alt="Capital OS Logo" className="logo-image" />

//         </div>

//         <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']}>
//           {localStorage.getItem('token') && ( // Check for token to determine login status
//             <Menu.Item key="4">
//               <Button type="link" onClick={handleLogout}>Logout</Button>
//             </Menu.Item>
//           )}
//         </Menu>
//       </Header>

//       <Content style={{ padding: '24px' }}>
//         <div className="site-layout-content">
//           <Tabs defaultActiveKey="1">
//             <TabPane tab="PDF Authenticator" key="1">
//               <Row gutter={[16, 16]}>
//                 {cardData.map((card, index) => (
//                   <Col xs={24} sm={12} md={8} lg={6} key={index}>
//                     <Card title={card.title} bordered={true} className="product-card">
//                       <p>{card.description}</p>
//                       <p>
//                         <b>Status:</b> {card.status}
//                       </p>
//                       <Button type="link" href={card.link} target="_blank">
//                         Open
//                       </Button>
//                     </Card>
//                   </Col>
//                 ))}
//               </Row>
//             </TabPane>
//             <TabPane tab="Vendor KYC" key="2">
              
//             </TabPane>
//           </Tabs>
//         </div>
//       </Content>
//     </Layout>
//   );
// };

// export default ClientDashboard;
import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import './Dashboard.css'; // Make sure this file has the necessary styles
import logo from './logo-f.png';
import atr from './atr.png';
import pdfa from './pdf-a.png';
import vendorkyc from './vendor-kyc.png';


const { Meta } = Card;

const ClientDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []); // Empty dependency array ensures this runs once on component mount

  // Check if user has client role
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'client') {
      navigate('/dashboard'); // Redirect if not client
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/'); // Redirect to login after logout
  };

  const cardData = [
    {
      title: 'PDF Authenticator',
      description: 'This expense bill authenticator is a tool that can help you identify if an expense bill PDF has been tampered with. It uses a machine learning model trained on a dataset of original and modified bills to detect inconsistencies and anomalies in the text of the bill.',
      status: 'Online',
      link: '/pdf-authenticator', // Replace with actual link
      image: pdfa
    },
    {
      title: 'Vendor KYC',
      description: 'A centralized platform to streamline the collection, verification, and management of vendor Know Your Customer (KYC) information. The tool automates the KYC process, ensuring compliance with regulatory requirements and reducing the risk of fraudulent activities.',
      status: 'Offline',
      link: '/vendor-kyc', // Replace with actual link
      image: vendorkyc
    },
    {
      title: 'Action Taken Report',
      description: 'A streamlined solution for tracking and documenting actions taken in response to incidents, audits, or feedback. The ATR Tool simplifies the process of recording decisions, assigning responsibilities, setting deadlines, and monitoring progress, ensuring transparency and accountability in addressing issues.',
      status: 'Offline',
      link: '/ater', // Replace with actual link
      image: atr
    }
  ];

  return (
    <Layout className="layout">
      <Header className="header">
        <div className="logo-container">
          <img src={logo} alt="Capital OS Logo" className="logo-image" />
        </div>

        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']}>
          {localStorage.getItem('token') && (
            <Menu.Item key="4">
              <Button type="link" onClick={handleLogout}>Logout</Button>
            </Menu.Item>
          )}
        </Menu>
      </Header>

      <Content style={{ padding: '24px' }}>
        <div className="site-layout-content">
          <Row gutter={[16, 16]} justify="center">
            {cardData.map((card, index) => (
              <Col xs={24} sm={24} md={12} lg={8} key={index}>
                <Card
                  hoverable
                  bordered={true}
                  className="dashboard-card"
                  cover={
                    <img
                      alt={card.title}
                      src={card.image}
                    />
                  }
                >
                  <Meta title={card.title} description={card.description} />
                  <p>
                    <b>Status:</b> {card.status}
                  </p>
                  <Button type="primary" href={card.link} target="_blank">
                    Open
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default ClientDashboard;
