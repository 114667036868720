// frontend/src/components/Footer.jsx
import React from 'react';
import { Layout } from 'antd';
import './Footer.css'

const { Footer: AntFooter } = Layout;

const Footer = () => (
  <AntFooter style={{ textAlign: 'center' }}>
    Copyright © 2024 Capitall IO. All rights reserved. |{' '}
    <a href="https://capitall.io" target="_blank" rel="noopener noreferrer">Official Website</a>{' '}
    |{' '}
    <a href="https://www.linkedin.com/company/capitallio" target="_blank" rel="noopener noreferrer">LinkedIn</a>
  </AntFooter>
);

export default Footer;
