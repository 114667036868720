import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './capitall-admin-logo.png'; // Path to your logo
import './Login.css'; // Create a new CSS file for styling

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://capitall-os.com/backend/auth/login', { username, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('role', response.data.role);
      message.success('Login successful!');
      if (response.data.role === 'client') navigate('/client/dashboard'); // Redirect to client home
      else navigate('/dashboard'); // Redirect to user dashboard
    } catch (error) {
      message.error('Login failed');
    }
  };

  return (
    <div className="login-container"> {/* Added container for centering */}
      <Card className="login-card">
        <div className="logo-wrapper"> 
          <img src={logo} alt="Logo" className="login-logo" />
        </div>
        <h3 style={{textAlign: 'center'}}>Login to your account</h3>
        <Form
          name="login-form"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email/Username" onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            onChange={(e) => setPassword(e.target.value)}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-button">
              Sign in
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center' , fontFamily: 'Space Mono, monospace'}}>
          Don't have account yet? <a href="#">Sign Up</a>
        </div>
      </Card>
    </div>
  );
};

export default Login;
