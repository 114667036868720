// frontend/src/Goals.jsx
import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, DatePicker, Button, message, List, Avatar, Tooltip, Progress, Menu, Switch, Modal, Col, Row, Space, Table } from 'antd';
import { CheckOutlined, ClockCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import Barchart, { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useNavigate, Link } from 'react-router-dom';
import { Header } from 'antd/es/layout/layout';
import logo from './logo-f.png';
import './Goals.css';

const { Content } = Layout;
const Goals = () => {
    const [viewMode, setViewMode] = useState('table');
    const [goals, setGoals] = useState([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [delayFrequencies, setDelayFrequencies] = useState({});
    const [isAddGoalModalVisible, setIsAddGoalModalVisible] = useState(false);
    const [usernameColors, setUsernameColors] = useState({}); // Use state to store colors
    const [isEditing, setIsEditing] = useState(false);
    const [editingGoal, setEditingGoal] = useState(null);

    const [editForm] = Form.useForm();

    const showEditModal = (goal) => {
        setEditingGoal(goal);
        setIsEditing(true);
        editForm.setFieldsValue({
            title: goal.title,
            description: goal.description,
        });
    };

    const handleEditOk = async () => {
    try {
      const values = await editForm.validateFields();
      const token = localStorage.getItem('token');
      // PATCH request to the new /goals/:id endpoint
      await axios.patch(`https://capitall-os.com/backend/goals/${editingGoal.id}`, values, {
        headers: { Authorization: `Bearer ${token}` }
      });
      message.success('Goal updated successfully!');
      setIsEditing(false);
      fetchGoals(); // Refresh the goal list
    } catch (error) {
      message.error('Failed to update goal');
    }
  };

    const handleEditCancel = () => {
        setIsEditing(false);
        editForm.resetFields();
    };

    const formatXAxis = (tickItem) => {
        return moment(tickItem, 'YYYY-MM-DD').format('MMM DD');
    };

    const showModal = () => {
        setIsAddGoalModalVisible(true);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
                setIsAddGoalModalVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleCancel = () => {
        setIsAddGoalModalVisible(false);
    };

    // fetchGoals function
    const fetchGoals = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://capitall-os.com/backend/goals', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setGoals(response.data.goals);
            setDelayFrequencies(response.data.delayFrequencies); // Store delay frequencies from backend

            const newColors = {};
            const uniqueUsernames = [...new Set(response.data.goals.map((goal) => goal.userId))];
            uniqueUsernames.forEach((username) => {
                newColors[username] = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            });
            setUsernameColors(newColors);
            console.log(response.data.goals);
            console.log(newColors);
        } catch (error) {
            message.error('Failed to fetch goals');
            if (error.response && error.response.status === 403) { // Check if error.response exists before accessing its properties
                navigate('/');
            }
        }
    };

    const initializeUsernameColors = () => {
        const newColors = {};
        const uniqueUsernames = [...new Set(goals.map((goal) => goal.user))];
        uniqueUsernames.forEach((username) => {
            newColors[username] = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        });
        setUsernameColors(newColors);
        console.log(usernameColors);
    };

    // useEffect
    useEffect(() => {
        fetchGoals();

    }, []);

    // Toggle View Mode
    const handleViewModeChange = (checked) => {
        setViewMode(checked ? 'table' : 'card');
    };

    const onFinish = async (values) => {
        try {
            const endOfWeek = moment(values.date).endOf('isoWeek');
            values.date = endOfWeek.format('YYYY-MM-DD');
            const token = localStorage.getItem('token');
            await axios.post('https://capitall-os.com/backend/goals', values, {
                headers: { Authorization: `Bearer ${token}` }
            });
            message.success('Goal created successfully!');
            form.resetFields();
            fetchGoals();
        } catch (error) {
            message.error('Failed to create goal');
        }
    };

    const markGoalAsDone = async (goalId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`https://capitall-os.com/backend/goals/${goalId}`, { status: 'Done' }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            message.success('Goal marked as done!');
            fetchGoals();
        } catch (error) {
            message.error('Failed to mark goal as done');
        }
    };

    const delayGoalToNextWeek = async (goalId) => {
        try {
            const token = localStorage.getItem('token');
            //   await axios.put(`https://capitall-os.com/backend/goals/${goalId}`, { dueDate: moment().endOf('isoWeek').add(1, 'week').format('YYYY-MM-DD') }, {
            //     headers: { Authorization: `Bearer ${token}` }
            //   });
            await axios.put(`https://capitall-os.com/backend/goals/${goalId}`, { dueDate: moment().endOf('isoWeek').add(1, 'week').format('YYYY-MM-DD'), status: 'Delayed' }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            message.success('Goal delayed to next week!');
            fetchGoals();
        } catch (error) {
            message.error('Failed to delay goal');
        }
    };

    const columns = [
        { title: 'Title', dataIndex: 'title', key: 'title' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        { title: 'Due Date', dataIndex: 'dueDate', key: 'dueDate', render: (dueDate) => moment(dueDate).format('YYYY-MM-DD') },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <span className={`status-${status.toLowerCase()}`}>{status}</span>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button primary onClick={() => markGoalAsDone(record.id)} icon={<CheckOutlined />} disabled={record.status === 'Done'}>Done</Button>
                    <Button danger onClick={() => delayGoalToNextWeek(record.id)} icon={<ClockCircleOutlined />} disabled={record.status === 'Done'}>Delay</Button>
                    <Button onClick={() => showEditModal(record)} icon={<EditOutlined />}>Edit</Button>
                </Space>
            ),
        },
    ];


    const data = Object.entries(delayFrequencies).map(([week, count]) => ({ week, count }));


    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        navigate('/'); // Redirect to login after logout
    };


    // Data for pie chart - calculate total vs. completed
    const totalGoals = goals.length;
    const completedGoals = goals.filter(goal => goal.status === 'Done').length;

    const pieChartData = [
        { name: 'Completed', value: completedGoals },
        { name: 'Pending', value: totalGoals - completedGoals },
    ];
    const COLORS = ['#00C49F', '#FFBB28']; // Green and orange

    return (
        <Layout className="layout">
            <Header className="header">
                <div className="logo-container">
                    <img src={logo} alt="Capital OS Logo" className="logo-image" />

                </div>

                <Menu theme="light" mode="horizontal" defaultSelectedKeys={['3']}>
                    {/* All menu items are now visible to everyone */}
                    <Menu.Item key="2">
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to="/goals">Goals</Link>
                    </Menu.Item>

                    {/* Logout button is still conditional for logged-in users */}
                    {localStorage.getItem('token') && ( // Check for token to determine login status
                        <Menu.Item key="4">
                            <Button type="link" onClick={handleLogout}>Logout</Button>
                        </Menu.Item>
                    )}
                </Menu>
            </Header>
            <Content style={{ padding: '24px' }}>
                <div className="site-layout-content">
                    <Button type="primary" icon={<PlusOutlined />} onClick={showModal} style={{ marginBottom: 16 }}>
                        Add Goal
                    </Button>

                    {/* <List
                        className="goal-list"
                        itemLayout="horizontal"
                        dataSource={goals}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Tooltip title="Mark as Done">
                                        <Button onClick={() => markGoalAsDone(item.id)} icon={<CheckOutlined />} disabled={item.status === 'Done'} />
                                    </Tooltip>,
                                    <Tooltip title="Delay to Next Week">
                                        <Button onClick={() => delayGoalToNextWeek(item.id)} icon={<ClockCircleOutlined />} disabled={item.status === 'Done'} />
                                    </Tooltip>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={item.title}
                                    description={
                                        <div className="goal-description">
                                            {item.description}
                                            <div className="due-date">
                                                Due: {moment(item.dueDate).format('YYYY-MM-DD')} ({item.status})
                                            </div>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    /> */}
                    {/* Goal Table */}
                    <Table dataSource={goals} columns={columns} rowKey="id" style={{ marginTop: 16 }} /> {/* Add style to provide margin */}

                    {/* Data Visualization */}
                    <Row gutter={[16, 16]}> {/* Added row for better layout */}
                        <Col span={12}> {/* Bar chart in first column */}
                            <h2>Goal Delay Frequency</h2>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={delayFrequencies}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="week" tickFormatter={formatXAxis} label={{ value: 'Week', position: 'insideBottomRight', offset: 0 }} dy={10} />
                                    <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft' }} />
                                    <Tooltip />
                                    <Legend />
                                    {/* Dynamically create bars for each user */}
                                    {Object.keys(delayFrequencies[0] || {}).filter(key => key !== 'week').map(userId => (
                                        <Bar dataKey={userId} fill={usernameColors[userId]} key={userId} name={userId} >
                                            <LabelList dataKey={userId} position="top" />
                                        </Bar>
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                        </Col>

                        <Col span={12}> {/* Pie chart in second column */}
                            <h2>Goal Completion Status</h2>
                            <ResponsiveContainer width="100%" height={250}>
                                <PieChart>
                                    <Pie
                                        data={pieChartData}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                        label
                                    >
                                        {pieChartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>


                    {/* Modal for Add Goal Form */}
                    <Modal title="Add Goal" visible={isAddGoalModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Form form={form} layout="vertical">
                            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter a title' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label="Due Date" name="date" rules={[{ required: true, message: 'Please select a due date' }]}>
                                <DatePicker picker="week" />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal title="Edit Goal" visible={isEditing} onOk={handleEditOk} onCancel={handleEditCancel}>
                        <Form form={editForm} layout="vertical">
                            <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please enter a title' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Content>
        </Layout>
    );
};

export default Goals;

