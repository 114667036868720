// frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import ClientDashboard from './ClientDashboard';
import PdfAuthenticator from './PdfAuthenticator';
import Goals from './Goals';
import Footer from './components/Footer';
import { Layout } from 'antd';
const { Content } = Layout; // Destructure Content from Layout

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout style={{ minHeight: '100vh' }}> {/* Make layout at least viewport height */}
        {/* <Header>This is your header</Header> */}
        <Content style={{ padding: '0 50px' }}>
          <div className="site-layout-content" style={{ overflowY: 'auto' }}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/goals" element={<Goals />} />
              <Route path="/client/dashboard" element={<ClientDashboard />} />
              <Route path="/pdf-authenticator" element={<PdfAuthenticator />} />
            </Routes>
          </div>
        </Content>
        <Footer />
      </Layout>
    </BrowserRouter>
  </React.StrictMode>
);
